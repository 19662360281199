import { Routes } from '@angular/router';

export enum HomeRoutesPaths {
    HOME = 'home',
    EXPLORE = 'explore',
    VISITING_SITES = 'visiting-sites'
}

export const homeRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: HomeRoutesPaths.HOME
    },
    {
        path: HomeRoutesPaths.HOME,
        loadComponent: () =>
            import('./pages/home/home-page.component').then(
                (c) => c.HomePageComponent
            )
    },
    {
        path: HomeRoutesPaths.EXPLORE,
        loadComponent: () =>
            import('./pages/explore-page/explore-page.component').then(
                (c) => c.ExplorePageComponent
            )
    },
    {
        path: HomeRoutesPaths.VISITING_SITES,
        loadComponent: () =>
            import('./pages/visiting-sites/visiting-sites.component').then(
                (c) => c.VisitingSitesComponent
            )
    }
];
